import React, {
  createContext, useEffect,
  useState
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { THEMES } from 'src/constants';

const defaultSettings = {
  direction: 'ltr',
  responsiveFontSizes: true,
  theme: THEMES.ONE_DARK
};

const storeSettings = (settings) => {
  localStorage.setItem('settings', JSON.stringify(settings));
};

const SettingsContext = createContext(defaultSettings);

export const SettingsProvider = ({ children }) => {
  const [currentSettings, setCurrentSettings] = useState(defaultSettings);

  const handleSaveSettings = (updatedSettings = {}) => {
    const mergedSettings = _.merge({}, currentSettings, updatedSettings);

    setCurrentSettings(mergedSettings);
    storeSettings(mergedSettings);
  };

  useEffect(() => {
    try {
      const storedData = localStorage.getItem('settings');

      if (storedData) {
        setCurrentSettings(JSON.parse(storedData));
      }
    } catch (error) {
      // If stored data is not a stringified JSON this might fail,
      // that's why we catch the error
    }
  }, []);

  return (
    <SettingsContext.Provider
      value={{
        settings: currentSettings,
        saveSettings: handleSaveSettings
      }}
    >
      {children}
    </SettingsContext.Provider>
  );
};

SettingsProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export const SettingsConsumer = SettingsContext.Consumer;

export default SettingsContext;
