import React, {
  useCallback, useState, useEffect, createContext
} from 'react';
import PropTypes from 'prop-types';
import { getIn } from 'formik';
import jwtDecode from 'jwt-decode';

const defaultAuth = {
  isLoggedIn: false,
  token: null,
  user: null,
  login: () => {},
  logout: () => {}
};

const AuthContext = createContext(defaultAuth);

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [token, setToken] = useState(null);

  const login = useCallback((userData) => {
    localStorage.setItem('userData', JSON.stringify(userData));

    setUser(userData.user);
    setToken(userData.token);
  }, []);

  const logout = useCallback(() => {
    setUser(null);
    setToken(null);

    localStorage.removeItem('userData');
  }, []);

  const isValidToken = (accessToken) => {
    if (!accessToken) {
      return false;
    }

    try {
      const decoded = jwtDecode(accessToken);
      const currentTime = Date.now() / 1000;
      return decoded.exp > currentTime;
    } catch (err) {
      return false;
    }
  };

  /* Auto login */
  useEffect(() => {
    const data = JSON.parse(localStorage.getItem('userData'));
    const accessToken = getIn(data, 'token');
    const userData = getIn(data, 'user');

    if (userData && isValidToken(accessToken)) {
      setUser(userData);
      setToken(accessToken);
    }
  }, []);

  return (
    <AuthContext.Provider value={{
      isLoggedIn: !!token,
      token,
      user,
      login,
      logout
    }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export const AuthConsumer = AuthContext.Consumer;

export default AuthContext;
