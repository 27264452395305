import React, { lazy, Suspense, Fragment } from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';

import DashboardLayout from 'src/layouts/DashboardLayout';

import { AuthGuard, GuestGuard, LoadingScreen } from 'src/components';

const routesConfig = [
  {
    exact: true,
    path: '/',
    component: () => <Redirect to="/app/revenues/overview" />
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/login',
    component: lazy(() => import('src/views/auth/LoginView'))
  },
  {
    path: '/app',
    guard: AuthGuard,
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: '/app',
        component: () => <Redirect to="/app/revenues/overview" />
      },
      {
        exact: true,
        path: '/app/account',
        component: lazy(() => import('src/views/pages/AccountView'))
      },
      // Dashboard
      {
        exact: true,
        path: '/app/reports/dashboard',
        component: lazy(() => import('src/views/reports/DashboardView'))
      },
      {
        exact: true,
        path: '/app/reports/dashboard-alternative',
        component: lazy(() => import('src/views/reports/DashboardAlternativeView'))
      },
      {
        exact: true,
        path: '/app/reports',
        component: () => <Redirect to="/app/reports/dashboard" />
      },
      // Organizations
      {
        exact: true,
        path: '/app/management/organizations',
        component: lazy(() => import('src/views/management/Organization/OrganizationListView'))
      },
      {
        exact: true,
        path: '/app/management/organizations/create',
        component: lazy(() => import('src/views/management/Organization/OrganizationCreateView'))
      },
      {
        exact: true,
        path: '/app/management/organizations/:organizationId',
        component: lazy(() => import('src/views/management/Organization/OrganizationDetailsView'))
      },
      {
        exact: true,
        path: '/app/management/organizations/:organizationId/edit',
        component: lazy(() => import('src/views/management/Organization/OrganizationEditView'))
      },
      // Contracts
      {
        exact: true,
        path: '/app/management/contracts',
        component: lazy(() => import('src/views/management/Contract/ContractListView'))
      },
      {
        exact: true,
        path: '/app/management/contracts/create',
        component: lazy(() => import('src/views/management/Contract/ContractCreateView'))
      },
      {
        exact: true,
        path: '/app/management/contracts/:contractId',
        component: lazy(() => import('src/views/management/Contract/ContractDetailsView'))
      },
      {
        exact: true,
        path: '/app/management/contracts/:contractId/edit',
        component: lazy(() => import('src/views/management/Contract/ContractEditView'))
      },
      // Invoices
      {
        exact: true,
        path: '/app/management/invoices',
        component: lazy(() => import('src/views/management/InvoiceListView'))
      },
      {
        exact: true,
        path: '/app/management/invoices/:invoiceId',
        component: lazy(() => import('src/views/management/InvoiceDetailsView'))
      },
      {
        exact: true,
        path: '/app/management',
        component: () => <Redirect to="/app/management/organizations" />
      },
      // Revenues
      {
        exact: true,
        path: '/app/revenues/overview',
        component: lazy(() => import('src/views/revenues/Overview'))
      },
      {
        exact: true,
        path: '/app/revenues',
        component: () => <Redirect to="/app/revenues/overview" />
      },
      // Transactions
      {
        exact: true,
        path: '/app/revenues/by-country',
        component: lazy(() => import('src/views/revenues/Transactions/Countries'))
      },
      {
        exact: true,
        path: '/app/revenues/by-subscription',
        component: lazy(() => import('src/views/revenues/Transactions/Subscriptions'))
      },
      {
        exact: true,
        path: '/app/revenues/by-media',
        component: lazy(() => import('src/views/revenues/Transactions/Medias'))
      },
      {
        exact: true,
        path: '/app/revenues/by-organization',
        component: lazy(() => import('src/views/revenues/Transactions/Organizations'))
      },
      {
        exact: true,
        path: '/app/revenues/by-payment-method',
        component: lazy(() => import('src/views/revenues/Transactions/PaymentMethods'))
      },
      // Calendar
      {
        exact: true,
        path: '/app/calendar',
        component: lazy(() => import('src/views/calendar/CalendarView'))
      },
      {
        exact: true,
        path: '/app/kanban',
        component: lazy(() => import('src/views/kanban/KanbanView'))
      },
      {
        exact: true,
        path: ['/app/chat/new', '/app/chat/:threadKey'],
        component: lazy(() => import('src/views/chat/ChatView'))
      },
      {
        exact: true,
        path: '/app/chat',
        component: () => <Redirect to="/app/chat/new" />
      },
      {
        exact: true,
        path: [
          '/app/mail/label/:customLabel/:mailId?',
          '/app/mail/:systemLabel/:mailId?'
        ],
        component: lazy(() => import('src/views/mail/MailView'))
      },
      {
        exact: true,
        path: '/app/mail',
        component: () => <Redirect to="/app/mail/all" />
      },
      {
        exact: true,
        path: '/app/projects/overview',
        component: lazy(() => import('src/views/projects/OverviewView'))
      },
      {
        exact: true,
        path: '/app/projects/browse',
        component: lazy(() => import('src/views/projects/ProjectBrowseView'))
      },
      {
        exact: true,
        path: '/app/projects/create',
        component: lazy(() => import('src/views/projects/ProjectCreateView'))
      },
      {
        exact: true,
        path: '/app/projects/:id',
        component: lazy(() => import('src/views/projects/ProjectDetailsView'))
      },
      {
        exact: true,
        path: '/app/projects',
        component: () => <Redirect to="/app/projects/browse" />
      },
      {
        exact: true,
        path: '/app/social/feed',
        component: lazy(() => import('src/views/social/FeedView'))
      },
      {
        exact: true,
        path: '/app/social/profile',
        component: lazy(() => import('src/views/social/ProfileView'))
      },
      {
        exact: true,
        path: '/app/social',
        component: () => <Redirect to="/app/social/profile" />
      },
      {
        exact: true,
        path: '/app/extra/charts/apex',
        component: lazy(() => import('src/views/extra/charts/ApexChartsView'))
      },
      {
        exact: true,
        path: '/app/extra/forms/formik',
        component: lazy(() => import('src/views/extra/forms/FormikView'))
      },
      {
        exact: true,
        path: '/app/extra/forms/redux',
        component: lazy(() => import('src/views/extra/forms/ReduxFormView'))
      },
      {
        exact: true,
        path: '/app/extra/editors/draft-js',
        component: lazy(() => import('src/views/extra/editors/DraftEditorView'))
      },
      {
        exact: true,
        path: '/app/extra/editors/quill',
        component: lazy(() => import('src/views/extra/editors/QuillEditorView'))
      },
      {
        component: () => <Redirect to="/" />
      }
    ]
  },
  {
    path: '**',
    component: () => <Redirect to="/" />
  }
];

const renderRoutes = (routes) => (
  routes && (
    <Suspense fallback={<LoadingScreen />}>
      <Switch>
        {routes.map((route, i) => {
          const Guard = route.guard || Fragment;
          const Layout = route.layout || Fragment;
          const Component = route.component;

          return (
            <Route
              key={i.toString()}
              path={route.path}
              exact={route.exact}
              render={(props) => (
                <Guard>
                  <Layout>
                    {route.routes ? (
                      renderRoutes(route.routes)
                    ) : (
                      <Component {...props} />
                    )}
                  </Layout>
                </Guard>
              )}
            />
          );
        })}
      </Switch>
    </Suspense>
  ));

const Routes = () => renderRoutes(routesConfig);

export default Routes;
