import React from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';

import { useAuth } from 'src/hooks';

const AuthGuard = ({ children }) => {
  const { isLoggedIn } = useAuth();

  if (!isLoggedIn) {
    return <Redirect to="/login" />;
  }

  return children;
};

AuthGuard.propTypes = {
  children: PropTypes.any
};

export default AuthGuard;
