import { getIn } from 'formik';

const descendingComparator = (a, b, orderBy) => {
  if (getIn(b, orderBy) < getIn(a, orderBy)) return -1;

  if (getIn(b, orderBy) > getIn(a, orderBy)) return 1;

  return 0;
};

const getComparator = (order, orderBy) => (order === 'desc'
  ? (a, b) => descendingComparator(a, b, orderBy)
  : (a, b) => -descendingComparator(a, b, orderBy));

export const applySort = (array, sort) => {
  const [orderBy, order] = sort.split('|');
  const comparator = getComparator(order, orderBy);
  const stabilizedThis = array.map((el, index) => [el, index]);

  stabilizedThis.sort((a, b) => {
    const item = comparator(a[0], b[0]);

    if (item !== 0) return item;

    return a[1] - b[1];
  });

  return stabilizedThis.map((el) => el[0]);
};

export const applyPagination = (array, page, limit) => array.slice(
  page * limit, page * limit + limit
);
