import jwtDecode from 'jwt-decode';
import axios from 'src/utils/axios';

export const loginWithEmailAndPassword = async () => {};

export const login = (user, token) => {
  localStorage.setItem('accessToken', token);
  localStorage.setItem('user', JSON.stringify(user));

  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
};

export const logout = () => {
  localStorage.removeItem('user');
  localStorage.removeItem('accessToken');
  delete axios.defaults.headers.common.Authorization;
};

export const updateProfile = (update) => new Promise((resolve, reject) => {
  axios.post('/api/account/profile', { update })
    .then((res) => {
      resolve(res.data);
    })
    .catch((err) => {
      reject(err.response);
    });
});

export const isValidToken = (accessToken) => {
  try {
    const decoded = jwtDecode(accessToken);
    const currentTime = Date.now() / 1000;
    return decoded.exp > currentTime;
  } catch (err) {
    return false;
  }
};

export const getToken = () => localStorage.getItem('accessToken');

export const getUser = () => localStorage.getItem('user');

export default {
  getToken,
  getUser,
  isValidToken,
  login,
  logout,
  loginWithEmailAndPassword,
  updateProfile
};
