import {
  useCallback,
  useEffect,
  useRef
} from 'react';
import axios from 'axios';
import { getIn } from 'formik';

import { useAuth } from 'src/hooks';

export default () => {
  const { token } = useAuth();

  const activeHttpRequests = useRef([]);

  const sendRequest = useCallback((options) => {
    const httpAbortCtrl = new AbortController();
    activeHttpRequests.current.push(httpAbortCtrl);

    return new Promise((resolve, reject) => {
      axios({
        baseURL: process.env.REACT_APP_API_URL,
        method: options.method || 'get',
        url: options.url,
        params: { ...options.params },
        headers: { ...options.headers },
        data: { ...options.data },
        signal: httpAbortCtrl.signal
      })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(getIn(error, 'response.data'));
        });
    });
  }, []);

  useEffect(() => {
    axios.interceptors.request.use(
      (response) => {
        response.headers.authorization = `Bearer ${token}`;
        return response;
      },
      (error) => { throw error; }
    );
  }, [token]);

  useEffect(() => {
    const activeRequests = activeHttpRequests.current;
    return () => {
      activeRequests.forEach((abortCtrl) => abortCtrl.abort());
    };
  }, []);

  return sendRequest;
};
