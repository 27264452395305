import React from 'react';
import PropTypes from 'prop-types';
import {
  TableHead,
  TableCell,
  TableRow,
  TableSortLabel,
  makeStyles
} from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {},
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  }
}));

const EnhancedTableHead = ({
  children, headCells, order, onRequestSort, sort
}) => {
  const classes = useStyles();

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            sortDirection={sort === headCell.id ? order : false}
          >
            <TableSortLabel
              active={sort === headCell.id}
              direction={sort === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {sort === headCell.id && (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              )}
            </TableSortLabel>
          </TableCell>
        ))}
        {children}
      </TableRow>
    </TableHead>
  );
};

EnhancedTableHead.propTypes = {
  children: PropTypes.node,
  headCells: PropTypes.array.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  sort: PropTypes.string.isRequired
};

export default EnhancedTableHead;
