/* eslint-disable arrow-body-style */

import authService from 'src/services/authService';

export const LOGIN_REQUEST = '@account/login-request';
export const LOGIN_SUCCESS = '@account/login-success';
export const LOGIN_FAILURE = '@account/login-failure';
export const SILENT_LOGIN = '@account/silent-login';
export const LOGOUT = '@account/logout';
export const UPDATE_PROFILE = '@account/update-profile';


export const loginRequest = () => {
  return {
    type: LOGIN_REQUEST
  };
};

export const loginSuccess = (user, token) => {
  return {
    type: LOGIN_SUCCESS,
    payload: {
      user,
      token
    }
  };
};

export const loginFailure = (error) => {
  return {
    type: LOGIN_FAILURE,
    payload: {
      error
    }
  };
};

export function login(email, password) {
  return async (dispatch) => {
    dispatch(loginRequest());
  };
}

export function logout() {
  return async (dispatch) => {
    authService.logout();

    dispatch({
      type: LOGOUT
    });
  };
}

export const updateProfile = (update) => {
  const request = authService.updateProfile(update);

  return (dispatch) => {
    request.then((response) => dispatch({
      type: UPDATE_PROFILE,
      payload: response.data
    }));
  };
};
