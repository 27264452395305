export const getInitials = (name = '') => name
  .replace(/\s+/, ' ')
  .split(' ')
  .slice(0, 2)
  .map((v) => v && v[0].toUpperCase())
  .join('');

export const toTitleCase = (name = '') => name
  .replace(/\s+/, ' ')
  .split(' ')
  .map((word) => word[0].toUpperCase() + word.slice(1).toLowerCase())
  .join(' ');
